import { useDispatch } from "react-redux";
import { addValue, subValue } from "../../store/modules/counter/actions.js";
const Counter = () => {
  const dispatch = useDispatch();
  return (
    <>
      <button onClick={() => dispatch(addValue(1))}>ADD +</button>
      <button onClick={() => dispatch(subValue(1))}>SUB -</button>
    </>
  );
};

export default Counter;
