import { ADD_COUNTER, SUB_COUNTER } from "./actionsTypes";

const reducerCounter = (state = 0, action) => {
  switch (action.type) {
    // Aqui você criará os cases do switch!
    case ADD_COUNTER:
      return state + action.value;

    case SUB_COUNTER:
      return state - action.value;

    default:
      return state;
  }
};

export default reducerCounter;
